/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages

        // slick basic
        $('.slider').slick({
          autoplay: true,
          dots: true,
          autoplaySpeed: 99999
        });


        // stop video playback on modal close
        // http://stackoverflow.com/a/25069916
        $(".video-modal").on('hidden.bs.modal', function (e) {
          var id = $(this).attr('id');
          var target = "#" + id + ".video-modal iframe";

          $(target).attr("src", $(target).attr("src"));
        });

	      /**********************************************
	       * ********************************************
	       * ******* Equal heights
	       * ********************************************
	       */
	      var screenWidth = $( window ).width();
	      ///// Equalize height in rows
	      var equalheight = function (container, minsize) {
		      var currentTallest = 0,
				      currentRowStart = 0,
				      rowDivs = [],
				      $el,
				      $currentDiv,
				      topPosition = 0;
		      $(container).each(function () {
			      screenWidth = $( window ).width();
			      var classes = $(container).attr('class').split(' ');
			      for(var i = 0; i < classes.length; i++) {
				      //console.log(classes[i]);
			      }

			      $el = $(this);
			      $($el).height('auto');

			      if(screenWidth > minsize){
				      topPostion = $el.position().top;
				      if (currentRowStart !== topPostion) {
					      for (currentDiv = 0; currentDiv < rowDivs.length; currentDiv++) {
						      rowDivs[currentDiv].height(currentTallest);
					      }
					      rowDivs.length = 0; // empty the array
					      currentRowStart = topPostion;
					      currentTallest = $el.height();
					      rowDivs.push($el);
				      } else {
					      rowDivs.push($el);
					      currentTallest = (currentTallest < $el.height()) ? ($el.height()) : (currentTallest);
				      }
				      for (currentDiv = 0; currentDiv < rowDivs.length; currentDiv++) {
					      rowDivs[currentDiv].height(currentTallest);
				      }
			      }
		      });
	      };
	      ////// end equalheight();
        function wrappedResize(parentClasses, elemClass) {
          var capHeight = 0;
          $(elemClass).css({height: 'auto'});

          if(!$('.navbar-toggle').is(':visible')){

            $(parentClasses).each(function(e){
              capHeight = 0;
              $(elemClass,$(this)).each(function(e){
                if($(this).outerHeight() > capHeight) {
                  capHeight = $(this).outerHeight();
                }
              });
              $(elemClass,$(this)).css({height: capHeight});
            });
          }
        }
	      ////////////////////////////////
	      ////////////////////////////////

	      //// general equalize heights call after load fallback
	      // function equalizeHeights(){
		     //  equalheight('.card', 767);
	      // }
	      // setTimeout(function(){ equalizeHeights(); }, 1100);

        function resizeFuntions() {
          wrappedResize('.home-section, .blog, .card-repeater','.cards .thumbnail', '.cards .process-content');
          wrappedResize('.repeater-tabs','.tablinks');
        }

        $('.dropdown').hover(function(e) {
          $(this).toggleClass('open');
        });

        $('.dropdown>.caret-wrapper').click(function(e) {
          e.stopPropagation();
          $(this).parent().toggleClass('mobile-open');
          $(this).toggleClass('flipped');
        });

        $('.no-link > a').click(function(e) {
          e.preventDefault();
        });

        setTimeout(function(){
          resizeFuntions();
        }, 1100);

        $(window).resize(function() {
          resizeFuntions();
        });

	      /**********************************************
	       * ********************************************
	       * ******* Basic Tabs
	       * ********************************************
	       */
	      ///// modified to handle multiple tabgroups on a single page
	      function openTab(evt, tabName, tabGroup) {
		      // variables
		      var i, tabcontent, tablinks;

		      tabGroup = '#' + tabGroup;
		      jQuery(tabGroup).find('.tabcontent').removeClass('active');
		      jQuery(tabGroup).find('.tablinks').removeClass('active');

		      // Show the current tab, and add an "active" class to the button that opened the tab
		      var compTab = tabGroup + ' #' + tabName + '-Tab';
		      jQuery(compTab).addClass('active');
		      var compTabContent = tabGroup + ' #' + tabName;
		      jQuery(compTabContent).addClass('active');
	      }

	      $('.tablinks').click(function() {
		      var val = $(this).attr('id');
		      var par = $(this).parent().parent().parent().attr('id'); //// pass the parent to set scope
		      openTab(event, val, par);
	      });

	      ////// end openTab() and calls;
	      //////////////////////////////////
	      //////////////////////////////////


      },
      finalize: function() {



      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS

	      function setHashNav(){
		      /**** this will handle hashchange instead of direct so that deep-linking will work ****/
		      $(window).on('hashchange', function (e) {
			      var hash = location.hash;
			      var goToThis = hash.slice( 1 );
			      goToThis ='#'+goToThis+'-section';
			      $( 'html, body' ).animate({
				      scrollTop: $(goToThis).offset().top
			      }, 1000, function() {
				      //
			      });
		      });
		      if (window.location.hash) {
			      jQuery(window).trigger('hashchange');
		      }

	      }

	      function setHashNavTrigger(){
		      setTimeout(setHashNav, 250);
	      }
	      setHashNavTrigger();

      }
    },
    // blog / news
    'blog': {
      init: function() {
        //
      },
	    finalize: function() {
		    function getRootUrl() {
			    return window.location.origin?window.location.origin+'/':window.location.protocol+'/'+window.location.host+'/';
		    }
		    var baseCompare = getRootUrl();
		    var fullCompare = window.location.href;
		    if( baseCompare !== fullCompare){
			    $(window).on('hashchange', function (e) {
				    var hash = location.hash;
				    var goToThis = hash.slice( 1 );
				    window.location.href = baseCompare + "/" + hash;
			    });
		    }
	   }
    },
	  // blog / news
	  'single': {
		  init: function() {			  //

		  },
		  finalize: function() {
			  function getRootUrl() {
				  return window.location.origin?window.location.origin+'/':window.location.protocol+'/'+window.location.host+'/';
			  }

			  var baseCompare = getRootUrl();
			  var fullCompare = window.location.href;
			  if( baseCompare !== fullCompare){
				  $(window).on('hashchange', function (e) {
					  var hash = location.hash;
					  var goToThis = hash.slice( 1 );
					  window.location.href = baseCompare + "/" + hash;
				  });
			  }
		  }
	  }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
